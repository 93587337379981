import React from "react";

const Footer = () => {
  return (<div></div>)
}

const Layout = (props) => {
  return (
    <div className="container">
      <div className="image-container">
        <img src="/chestnut.jpg" className='headerimage' />
      </div>
      <div className="image-container">
        <h4 style={{ color: "#9d9d9d", fontWeight: "bold" }}>105 Chestnut Street Lakewood NJ 08701</h4>
      </div>
      <div className="well">
        {props.children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
