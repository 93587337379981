import React, { useState, useEffect } from 'react';
import axios from 'axios';

const formatCurrency = amount => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return formatter.format(amount);
}

const Totals = () => {
    const [totalData, setTotalData] = useState();
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('authenticated') === 'true');
    const [pin, setPin] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (isAuthenticated) {
            (async () => {
                const { data } = await axios.get('/api/gettotals');
                setTotalData(data);
            })();
        }
    }, [isAuthenticated]);

    const handleAuthentication = () => {
        if (pin === 'ChestnutTech$') {
            setIsAuthenticated(true);
            localStorage.setItem('authenticated', 'true');
            setError('');
        } else {
            setError('Invalid Password. Please try again.');
        }
    };

    if (!isAuthenticated) {
        return (
            <div className="container">
                <div className="row" style={{ height: '100vh', textAlign: 'center', marginTop: 100 }}>
                    <div className="col-md-4 col-md-offset-4">
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                value={pin}
                                onChange={e => setPin(e.target.value)}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        handleAuthentication();
                                    }
                                }}
                            />
                        </div>
                        <button onClick={handleAuthentication} className="btn btn-primary btn-block">Authenticate</button>
                        {error && <div className="alert alert-danger" role="alert">{error}</div>}
                    </div>
                </div>
            </div>
        );
    }

    if (!totalData) {
        return <h1>Loading...</h1>
    }

    return (
        <>
            <h3>Total Mechiras: {totalData.mechirasCount}</h3>
            <h3>Total Donations: {formatCurrency(totalData.donationsTotal)}</h3>
            <div className='table-responsive'>
                <table className='table table-hover table-striped table-bordered'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {totalData.donationsBreakdown.map((r, idx) => (
                            <tr key={idx}>
                                <td>{r.firstName} {r.lastName}</td>
                                <td>{r.email}</td>
                                <td>{formatCurrency(r.total_donation_amount)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Totals;
