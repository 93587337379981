import React, { useState, useContext } from 'react';
import TextField from './TextField';
import { produce } from 'immer';
import axios from 'axios';
import Loader from "./Loader";
import { UserInfoContext } from '../UserInfoContext';
import './donations.css';

const buttonStyles = {
  fontSize: 20,
  margin: 4
}

const RadioButtons = ({ onChange, selectedAmount }) => {
  const amounts = [18, 36, 50, 72, 180, 360, 500];

  return (
    <div className="row">
      {amounts.map(a => {
        return <div key={a} className="col-xs-12 custom-col-desktop">
          <button type="button" style={buttonStyles} onClick={() => onChange(a)} className={`btn btn-block ${selectedAmount === a ? 'btn-default' : 'btn-primary'}`}>${a}</button>
        </div>
      })}
    </div>
  )
}


function range(start, stop) {
  var a = [], b = start;
  while (b < stop) {
    a.push(b++);
  }
  return a;
}


const Donations = () => {

  const [selectedAmount, setSelectedAmount] = useState('');
  const [xError, setXerror] = useState("");
  const [hasError, setHasError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { userInfo } = useContext(UserInfoContext);

  const [data, setData] = useState({
    amount: '',
    cardNumber: '',
    expirationMonth: new Date().getMonth() + 1,
    expirationYear: new Date().getFullYear(),
    cvv: ''
  });

  const [errors, setErrors] = useState({
    amount: false,
    cardNumber: false,
    expirationMonth: false,
    expirationYear: false,
    cvv: false
  })

  const [loading, setLoading] = useState(false);

  const onRadioChange = amount => {
    setSelectedAmount(amount);
    setData({ ...data, amount });
  }

  const onTextInputChange = (e, name) => {
    const nextState = produce(data, draft => {
      draft[name] = e.target.value;
    });
    const newErrorState = produce(errors, draft => {
      draft[name] = false;
    });
    setData(nextState);
    setHasError(false);
    setErrors(newErrorState);
    setSelectedAmount("");
  }

  const validate = () => {
    const errors = Object.keys(data).reduce((acc, current) => {
      acc[current] = !data[current];
      return acc;
    }, {});
    setErrors(errors);
    return errors;
  }

  const onDonateClick = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (!Object.keys(errors).some(key => errors[key])) {
      setLoading(true);
      const response = await axios.post('/api/donate', { ...data, ...userInfo });
      setLoading(false);
      if (response.data.xStatus !== "Approved") {
        setXerror(response.data.xError);
      } else {
        setSubmitted(true);
      }
    } else {
      setHasError(true);
    }
  }
  const LoaderOrBtn = loading ? <Loader /> : <button className={`btn ${xError || hasError ? "btn-danger" : "btn-primary"} btn-block`}>Donate</button>;
  if (submitted) {
    return <div className="row">
      <div className="col-md-6 col-md-offset-3">
        <h3>TIZKU LMITZVOS! Wishing you and your family a joyous Pesach!</h3>
      </div>
    </div>
  }
  return (
    <form onSubmit={onDonateClick}>
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <p className='info-text top-space'>
          As is customary in all Jewish communities, upon the completion of the Chometz sale transaction, we give the Rabbi a monetary gift for his service.
<br /><br />
Due to the difficult financial situation that many currently find themselves in, Rabbi Greenfeld has requested that all monetary gifts should be given to his Emergency Pesach Fund to help people in his community who desperately need help making Pesach this year.
<br /><br />
Please show your appreciation to Rabbi Greenfeld for his service, and make your generous tax deductible donation to help families who currently find themselves in a financial crisis unable to make Yom Tov.
<br /><br />
TIZKU LMITZVOS!
<br /><br />
Wishing you and your family a joyous Pesach!
<br /><br />
          </p>
        </div>
      </div>
      <RadioButtons onChange={onRadioChange} selectedAmount={selectedAmount} />
      <div className="row top-space">
        <TextField
          sizingClassNames="col-md-2"
          labelText="Amount"
          hasError={errors.amount}
          value={data.amount}
          onChange={e => onTextInputChange(e, 'amount')}
          placeholder="Amount" />
        <TextField
          sizingClassNames="col-md-5"
          labelText="Card Number"
          hasError={errors.cardNumber}
          value={data.cardNumber}
          onChange={e => onTextInputChange(e, 'cardNumber')}
          placeholder="Credit Card Number" />
        <div className="col-md-2">
          <div className="form-group">
            <label>Expiration Month</label>
            <select value={data.expirationMonth} onChange={e => onTextInputChange(e, 'expirationMonth')} className="form-control">
              {range(1, 13).map(m => <option key={m} value={m}>{m}</option>)}
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label>Expiration Year</label>
            <select value={data.expirationYear} onChange={e => onTextInputChange(e, 'expirationYear')} className="form-control">
              {range(2024, 2034).map(m => <option key={m} value={m}>{m}</option>)}
            </select>
          </div>
        </div>
        <TextField
          sizingClassNames="col-md-1"
          labelText="CVV"
          hasError={errors.cvv}
          value={data.cvv}
          onChange={e => onTextInputChange(e, 'cvv')}
          placeholder="CVV" />
      </div>
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          {LoaderOrBtn}
          {xError && <span className="text-danger">{xError}</span>}
        </div>
      </div>
    </form>
  )
}

export default Donations;