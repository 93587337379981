import React, { useState, useRef, useEffect, useContext } from "react";
import SignatureCanvas from 'react-signature-canvas';
import { produce } from 'immer';
import axios from 'axios';
import TextField from '../components/TextField';
import Loader from "../components/Loader";
import { Modal, Button } from 'react-bootstrap';
import { UserInfoContext } from '../UserInfoContext';


const stateList = new Array("AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "GU", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MH", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "PR", "PW", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VI", "VT", "WA", "WI", "WV", "WY", "Other");

const MechirasForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [showBioModal, setShowBioModal] = useState(false);
  const [showIsraelModal, setShowIsraelModal] = useState(false);
  const [showUSModal, setShowUSModal] = useState(false);
  const [locationValue, setLocationValue] = useState('EST');
  const [base64, setBase64] = useState('');

  const { setUserInfo } = useContext(UserInfoContext);

  const [mechira, setMechira] = useState({
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    city: '',
    state: 'NJ',
    zipCode: '',
    chametzLocations: '',
    phoneNumber: '',
    liquorQty: '',
    liquorValue: '',
    breadCakeCerealQty: '',
    breadCakeCerealValue: '',
    perfumesQty: '',
    perfumesValue: '',
    medicinesQty: '',
    medicinesValue: '',
    othersQty: '',
    othersValue: '',
    signature: ''
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    address: false,
    email: false,
    city: false,
    state: false,
    zipCode: false,
    chametzLocations: false,
    phoneNumber: false,
    liquorQty: false,
    liquorValue: false,
    breadCakeCerealQty: false,
    breadCakeCerealValue: false,
    perfumesQty: false,
    perfumesValue: false,
    medicinesQty: false,
    medicinesValue: false,
    othersQty: false,
    othersValue: false
  });
  const [hasSigned, setHasSigned] = useState(false);
  const sigRef = useRef();

  const onTextInputChange = (e, name) => {
    const nextState = produce(mechira, draft => {
      draft[name] = e.target.value;
    });
    const newErrorState = produce(errors, draft => {
      draft[name] = false;
    });
    setMechira(nextState);
    setHasError(false);
    setErrors(newErrorState);
  }

  useEffect(() => {
    // if (locationValue === 'EST') {
    //   return;
    // }
    // if (locationValue === 'Israel') {
    //   setShowIsraelModal(true);
    // }
    // else if (locationValue === 'USOther') {
    //   setShowUSModal(true);
    // }
  }, [locationValue]);

  const validate = () => {
    const errors = Object.keys(mechira).reduce((errors, key) => {
      if (key.includes('Qty')) {
        const keyName = key.replace('Qty', '');
        const qty = mechira[key];
        const value = mechira[`${keyName}Value`];
        const isValid = (qty && value) || (!qty && !value);
        errors[key] = !isValid && !qty;
        errors[`${keyName}Value`] = !isValid && !value;
        return errors;
      }
      else if (key.includes('Value') || key === 'signature') {
        return errors;
      }
      errors[key] = !mechira[key];
      return errors;
    }, {});
    setErrors(errors);
    return errors;
  }


  const onFormSubmit = async e => {
    e.preventDefault();
    setSubmitClicked(true);
    const errors = validate();
    if (!Object.keys(errors).some(key => errors[key]) && hasSigned) {
      setLoading(true);
      setHasError(false);
      await axios.post('/api/addmechira', { ...mechira, base64, sellOnThirteen: locationValue === 'Israel' });
      setLoading(false);
      setUserInfo({ email: mechira.email, firstName: mechira.firstName, lastName: mechira.lastName });
      props.history.push("/success");
    } else {
      setHasError(true);
    }
  }

  const LoaderOrButton = loading ? <Loader /> : <div>
    <button className={`btn ${hasError ? "btn-danger" : "btn-primary"} btn-block`}>Submit</button>
    <span>Note: The cut-off time for submissions is Thursday evening, April 10th, 2025 at 11:00 PM EST</span>
  </div>;

  const onShowModalClick = e => {
    e.preventDefault();
    setShowBioModal(true);
  }

  const onSignatureEnd = () => {
    setHasSigned(true);
    setBase64(sigRef.current.toDataURL('image/jpeg'));
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <p className='info-text top-space'>
          For all those who are unable to sell the Chometz in person this year, we have arranged with the מרא דאתרא of Chestnut Shul (Lakewood, NJ), 
          Rabbi Yosef Greenfeld שליט״א, a Musmuch from BMG, & a renowned Posek in Lakewood NJ, who has been relied upon by the entire Lakewood community 
          to sell their Chometz for many years, for the availability of מכירת חמץ to be done via the following online form LECHATCHILA.  
          (For more info on Rabbi Greenfeld click <a href="#" type="button" onClick={onShowModalClick}>here</a>).
          Please fill out the form to the best of your ability, and sign the signature pad at the bottom. 
          Should you have any questions, feel free to email the Rov at  <a href="mailto:rov@sellmychometz.com">rov@sellmychometz.com</a>.
          </p>
        </div>
      </div>
      <form onSubmit={onFormSubmit}>
        <div className="row top-space">
          <TextField
            sizingClassNames="col-md-3"
            labelText="First Name"
            hasError={errors.firstName}
            value={mechira.firstName}
            onChange={e => onTextInputChange(e, 'firstName')}
            placeholder="First Name" />
          <TextField
            sizingClassNames="col-md-3"
            labelText="Last Name"
            hasError={errors.lastName}
            value={mechira.lastName}
            onChange={e => onTextInputChange(e, 'lastName')}
            placeholder="Last Name" />
          <TextField
            sizingClassNames="col-md-3"
            labelText="Phone Number"
            hasError={errors.phoneNumber}
            value={mechira.phoneNumber}
            onChange={e => onTextInputChange(e, 'phoneNumber')}
            placeholder="Phone Number" />
          <TextField
            sizingClassNames="col-md-3"
            labelText="Email Address"
            hasError={errors.email}
            value={mechira.email}
            onChange={e => onTextInputChange(e, 'email')}
            placeholder="Email Address" />
        </div>
        <div className="row">
          <TextField
            sizingClassNames="col-md-3"
            labelText="Address"
            hasError={errors.address}
            value={mechira.address}
            onChange={e => onTextInputChange(e, 'address')}
            placeholder="Address" />
          <TextField
            sizingClassNames="col-md-3"
            labelText="City"
            hasError={errors.city}
            value={mechira.city}
            onChange={e => onTextInputChange(e, 'city')}
            placeholder="City" />
          <div className="col-md-3">
            <div className="form-group">
              <label>State</label>
              <select value={mechira.state} onChange={e => onTextInputChange(e, 'state')} className="form-control">
                {stateList.map(state => {
                  return (
                    <option value={state} key={state}>
                      {state}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <TextField
            sizingClassNames="col-md-3"
            labelText="Zip/Postal Code"
            hasError={errors.zipCode}
            value={mechira.zipCode}
            onChange={e => onTextInputChange(e, 'zipCode')}
            placeholder="Zip/Postal Code" />
        </div>
        <div className="row top-space">
          <div className="col-md-8 col-md-offset-2">
            <p className="info-text">
              I hereby authorize Rabbi Yosef Y. Greenfeld to negotiate the sale of all Chometz in my possession, or affixed to my possessions, located in the following rooms, closets, and locations:
            </p>
          </div>
        </div>
        <div className="row top-space">
          <div className="col-md-12">
            <div className={`form-group ${errors.chametzLocations ? 'has-error' : ''}`}>
              <textarea rows="7"
                value={mechira.chametzLocations}
                className="form-control"
                placeholder="Enter locations here... (you may also include other geographical locations such as an office etc, entering the address and locations of Chometz at that address)"
                onChange={e => onTextInputChange(e, 'chametzLocations')} />
            </div>
          </div>
        </div>
        <div className="row category-group top-space">
          <div className="col-md-2 col-md-offset-2">
            <div>
              Liquors:
            </div>
          </div>
          <TextField
            sizingClassNames="col-md-3"
            hasError={errors.liquorQty}
            value={mechira.liquorQty}
            onChange={e => onTextInputChange(e, 'liquorQty')}
            placeholder="Approximate Quantity" />
          <TextField
            sizingClassNames="col-md-3"
            hasError={errors.liquorValue}
            value={mechira.liquorValue}
            onChange={e => onTextInputChange(e, 'liquorValue')}
            placeholder="Approximate Value" />
        </div>
        <div className="row category-group">
          <div className="col-md-2 col-md-offset-2">
            <div>
              Bread, Cakes, Cereals:
            </div>
          </div>
          <TextField
            sizingClassNames="col-md-3"
            hasError={errors.breadCakeCerealQty}
            value={mechira.breadCakeCerealQty}
            onChange={e => onTextInputChange(e, 'breadCakeCerealQty')}
            placeholder="Approximate Quantity" />
          <TextField
            sizingClassNames="col-md-3"
            hasError={errors.breadCakeCerealValue}
            value={mechira.breadCakeCerealValue}
            onChange={e => onTextInputChange(e, 'breadCakeCerealValue')}
            placeholder="Approximate Value" />
        </div>
        <div className="row category-group">
          <div className="col-md-2 col-md-offset-2">
            <div>
              Perfumes:
            </div>
          </div>
          <TextField
            sizingClassNames="col-md-3"
            hasError={errors.perfumesQty}
            value={mechira.perfumesQty}
            onChange={e => onTextInputChange(e, 'perfumesQty')}
            placeholder="Approximate Quantity" />
          <TextField
            sizingClassNames="col-md-3"
            hasError={errors.perfumesValue}
            value={mechira.perfumesValue}
            onChange={e => onTextInputChange(e, 'perfumesValue')}
            placeholder="Approximate Value" />
        </div>
        <div className="row category-group">
          <div className="col-md-2 col-md-offset-2">
            <div>
              Medicines:
            </div>
          </div>
          <TextField
            sizingClassNames="col-md-3"
            hasError={errors.medicinesQty}
            value={mechira.medicinesQty}
            onChange={e => onTextInputChange(e, 'medicinesQty')}
            placeholder="Approximate Quantity" />
          <TextField
            sizingClassNames="col-md-3"
            hasError={errors.medicinesValue}
            value={mechira.medicinesValue}
            onChange={e => onTextInputChange(e, 'medicinesValue')}
            placeholder="Approximate Value" />
        </div>
        <div className="row category-group">
          <div className="col-md-2 col-md-offset-2">
            <div>
              Others:
            </div>
          </div>
          <TextField
            sizingClassNames="col-md-3"
            hasError={errors.othersQty}
            value={mechira.othersQty}
            onChange={e => onTextInputChange(e, 'othersQty')}
            placeholder="Approximate Quantity" />
          <TextField
            sizingClassNames="col-md-3"
            hasError={errors.othersValue}
            value={mechira.othersValue}
            onChange={e => onTextInputChange(e, 'othersValue')}
            placeholder="Approximate Value" />
        </div>
        <div className="row">
          <div className="col-md-5 col-md-offset-2" style={{ marginTop: 8, fontWeight: 'bold' }}>
            <span>Please indicate where you'll be spending the entire Pesach</span>
          </div>
          <div className="col-md-3">
            <select value={locationValue} className="form-control" onChange={e => setLocationValue(e.target.value)}>
              <option value="Israel">EU / UK / Israel</option>
              <option value="EST" selected>Eastern US</option>
              <option value="USOther">US Other (CST/MST/PST)</option>
            </select>
          </div>
        </div>
        <div className="row top-space">
          <div className="col-md-10 col-md-offset-1">
            <ol>
              <li>Rabbi Yosef Y. Greenfeld is authorized to rent, or sublease, the rooms, or part of the
                            rooms, in which the Chometz is located, to the purchaser of the Chometz.</li>
              <li>The purchaser may have access anytime he wishes to take the Chometz.</li>
              <li>The key, giving the purchaser access to the Chometz, can be obtained by calling {mechira.phoneNumber || '(###) ###-####'}</li>
              <li>Negotiating the sale of any Chometz not identified above, but to which I have legal
              ownership, or responsibility, entirely, or partially, and/or Chometz which may arrive during
                            Pesach, is also included in this authorization.</li>
              <li>Rabbi Greenfeld may choose any legal instrument in accord with Halacha for negotiating this
                            sale.</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <div className="form-group">
              <label>Sign Here</label>
              <div className="error" error={!hasSigned}>
                <SignatureCanvas onBegin={() => setSubmitClicked(false)} className="form-control" onEnd={onSignatureEnd} backgroundColor={'rgba(255,255,255,255)'} ref={sigRef} penColor='black'
                  canvasProps={{ className: 'form-control', style: { width: '100%', height: 150, border: `1px solid ${submitClicked && !hasSigned ? "salmon" : "black"}` } }} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            {LoaderOrButton}
            {hasError && <span className="text-danger">Please check highlighted fields</span>}
            <div style={{textAlign: 'center'}}>
              <h5>לע״נ שלמה אהרן בן אברהם ע״ה</h5>
              <h5>לע״נ פעשא רייכא בת יהודה ליב וועלוועל ע״ה</h5>
            </div>
          </div>
        </div>
      </form>

      <Modal show={showBioModal} onHide={() => setShowBioModal(false)}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <p>Rabbi Yosef Greenfeld has been the Rav of the Chestnut community since 2006.
In this position, Rabbi Greenfeld continues to act as an advocate for many
members of the community, heads a gemach to support those members who are
struggling financially, has started and leads a daily shiur among many of his
congregants, and acts as both halachic and hashkafik advisor to his community.</p>

<p>In addition to his duties as the Rav of Chestnut, Rabbi Greenfeld a preeminent
expert in taharas hamishpacha and shalom bayis, gives chosson shmoozes for
many BMG yungerleit, many of which who continue to ask Rabbi Greenfeld
their shailos and for hadracha long after they are married. As an experienced and
well-respected chosson teacher, Rabbi Greenfeld also teaches and trains
other chosson teachers. Rabbi Greenfeld has also trained by and is one of the
official rabbonim for the infertility organization, A Time and is a leader in the field
of infertility and halacha.</p>
<p>Rabbi Greenfeld is renowned for his grasp of inyunim of shalom bayis and is called
from around the world by couples seeking counseling and advice on how to
improve their shalom bayis. In addition, Rabbi Greenfeld coaches therapists in the
intricacies of shalom bayis so that they can better serve their Jewish clientele.</p>
<p>Rabbi Greenfeld continues to teach and spread his wealth of knowledge in his
many roles as an educator. In this capacity, he is a Rebbe in Bais Meir, and
teaches Family Living in the New Seminary and Halacha in Bnos Chaim Seminary.
Besides these roles, Rabbi Greenfeld has many speaking engagements, including
addressing mental health professionals at the Agudath Israel convention and
delivering many shiurim on <a target='_blank' href="https://www.torahanytime.com/#/speaker?l=1213">Torah Anytime</a>. Rabbi Greenfeld is also one of the
official rabbonim of Agudath Israel of America.</p>
<p>Rabbi Greenfeld learned in Yeshiva of Passaic under Reb. Meir Stern and
additionally, learned with Reb. Mordechai Schwab. Rabbi Greenfeld received
his smicha at BMG and took shimush from Rabbi Avrohom Greenfeld, Reb
Shmuel Meir Katz, and Reb Shmuel Felder.</p>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowBioModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showIsraelModal}>
        <Modal.Header>
        </Modal.Header>
        <Modal.Body>
          <p className="info-text"><strong>PLEASE NOTE: </strong>For those who will be spending the entire Pesach in the UK, EU, 
          (or any other time zone that will end Pesach before NY time) you must wait AFTER Pesach to use ANY Chometz until April 30th, 2024 - 9:30PM EST (NY time).
           Please use the button below to acknowledge this message. We DON'T recommend using this site if you keep 7 days of Pesach in Eretz Yisroel, 
           however if you keep 8 days of Pesach in Eretz Yisroel, you must wait until April 30th, 2024 - 9:30PM EST (NY time) to use ANY Chometz.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowIsraelModal(false)}>Acknowledge</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUSModal}>
        <Modal.Header>
        </Modal.Header>
        <Modal.Body>
          <p className="info-text"><strong>PLEASE NOTE: </strong>
For those who will be spending the entire Pesach in the Continental USA Mainland in the CST, MST, PST time zones, 
being that the Chometz will be "bought back" while it's still Yom Tov for you, you are NOT making the Rov a "shliach" (agent) to retrieve back your 
Chometz when the Rov buys it back after Pesach in Lakewood NJ (EST time zone). However, when your Yom Tov ends, you will automatically be "koneh" 
(acquire ownership of) the Chometz back by opening your Chometz area.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowUSModal(false)}>Acknowledge</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
};

export default MechirasForm;