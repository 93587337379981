import React from 'react';

const TextField = ({ sizingClassNames, labelText, hasError, value, onChange, placeholder }) => {
  return (
    <div className={sizingClassNames}>
      <div className={`form-group ${hasError ? 'has-error' : ''}`}>
        {!!labelText && <label>{labelText}</label>}
        <input type="text" className="form-control" placeholder={placeholder} value={value} onChange={onChange} />
      </div>
    </div>);
}

export default TextField;