import React, { useState, createContext } from 'react';

const UserInfoContext = createContext(null);

const UserInfoContextWrapper = ({ children }) => {
  const [userInfo, setUserInfo] = useState({
    email: '',
    firstName: '',
    lastName: ''
  });

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  )
}

export { UserInfoContextWrapper, UserInfoContext }