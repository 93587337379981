import React, { useEffect } from "react";
import Donations from '../components/Donations';


const Confirmation = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <h3>Thank you. Your Mechiras Chometz contract has been received.</h3>
        </div>
      </div>
      <Donations />
    </>
  );
};

export default Confirmation;
